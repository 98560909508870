import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['template', 'samples']

  addSample (e) {
    e.preventDefault()
    this.samplesTarget.insertAdjacentHTML(
      'beforeend',
      this.templateTarget.innerHTML
    )
  }

  removeSample (e) {
    e.preventDefault()
    if (this.samplesTarget.children.length !== 1) {
      e.target.parentElement.parentElement.remove()
    }
  }
}
