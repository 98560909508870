import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['template', 'questions']

  addQuestion (e) {
    e.preventDefault()
    this.questionsTarget.insertAdjacentHTML(
      'beforeend',
      this.templateTarget.innerHTML
    )
  }

  removeQuestion (e) {
    e.preventDefault()
    if (this.questionsTarget.children.length !== 1) {
      e.target.parentElement.parentElement.remove()
    }
  }
}
