import { Controller } from '@hotwired/stimulus'
import { Modal } from 'bootstrap'

export default class extends Controller {
  static values = {
    id: Number,
    fromEmail: String,
    body: String,
    answer: String
  }

  connect () {
    this.modal = Modal.getOrCreateInstance(document.getElementById('email_modal'))
  }

  showModal () {
    document.getElementById('email_modal_label').innerText = this.fromEmailValue
    document.getElementById('email_id').value = this.idValue
    document.getElementById('email_from').textContent = this.fromEmailValue
    document.getElementById('email_body').textContent = this.bodyValue
    document.getElementById('email_answer').value = this.answerValue
    this.modal.show()
  }

  hideModal () {
    this.modal.hide()
  }

  submit (e) {
    if (document.getElementById('email_answer').value !== '') {
      this.modal.hide()
    }
  }
}
