import { Controller } from '@hotwired/stimulus'
import { Modal } from 'bootstrap'

export default class extends Controller {
  static values = {
    name: String,
    value: String
  }

  connect () {
    this.modal = Modal.getOrCreateInstance(document.getElementById('trigger_modal'))
  }

  showModal () {
    document.getElementById('trigger_modal_label').innerText = this.nameValue
    document.getElementById('trigger_name').value = this.nameValue
    document.getElementById('trigger_value').value = this.valueValue
    this.modal.show()
  }

  hideModal () {
    this.modal.hide()
  }

  submit (e) {
    if (document.getElementById('trigger_value').value !== '') {
      this.modal.hide()
    }
  }
}
