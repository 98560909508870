import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['fullText', 'croppedText', 'toggleButton']
  static values = {
    cropping: Boolean,
    showMoreText: String,
    showLessText: String
  }

  connect () {
    if (this.croppingValue) {
      this.fullTextTarget.hidden = true
      this.croppedTextTarget.hidden = false
      this.toggleButtonTarget.innerHTML = this.showMoreTextValue
    }
  }

  toggleCropped (e) {
    e.preventDefault()
    this.fullTextTarget.hidden = !this.fullTextTarget.hidden
    this.croppedTextTarget.hidden = !this.croppedTextTarget.hidden
    this.croppingValue = !this.croppingValue
    this.toggleButtonTarget.innerHTML = this.croppingValue ? this.showMoreTextValue : this.showLessTextValue
  }
}
