import { Controller } from '@hotwired/stimulus'
import { Collapse } from 'bootstrap'
import { cable } from '@hotwired/turbo-rails'

export default class extends Controller {
  static targets = ['chatPanel']

  connect () {
    this.subscribe()
    this.scrollMessages()
  }

  subscribe () {
    const turboStreamFromTag = document.getElementById('chatbot_stream')
    const channelName = turboStreamFromTag.getAttribute('channel')
    const signedStreamName = turboStreamFromTag.getAttribute('signed-stream-name')

    const scrollMessages = this.scrollMessages.bind(this)

    this.channel = cable.subscribeTo({ channel: channelName, signed_stream_name: signedStreamName }, {
      received (data) {
        setTimeout(scrollMessages, 100)
      }
    })
  }

  scrollMessages () {
    const chatContainer = document.getElementById('messages')
    if (chatContainer) chatContainer.scrollTop = chatContainer.scrollHeight
  }

  collapse () {
    // eslint-disable-next-line no-new
    new Collapse(this.chatPanelTarget, { toggle: true })
    this.scrollMessages()
  }
}
