import { Controller } from '@hotwired/stimulus'
import { circliful } from 'js-plugin-circliful';

export default class extends Controller {
  static values = {
    nps: Number
  }

  connect() {
    console.log('!!!!')
    this.show_circle()
  }

  show_circle() {
    circliful.newCircle({
      percent: this.npsValue,
      id: 'nps-circle',
      type: 'simple',
    });
  }
}
